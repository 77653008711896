import React from 'react'
import notebook from '../scss/notebook.module.scss';
import ParchmentBottom from '../images/Parchment_Bottom.png';
import edgeTop from '../images/note-edge-top.png';
export default function NoteSectionEnd({children}) {
  return (
    <section className={notebook.noteSection}>
        <img src={edgeTop} alt="" className={notebook.noteDivider}/>
        <div className={notebook.noteContent}>{children}</div>
        <img src={ParchmentBottom} alt="" className={notebook.noteEnd} />
    </section>
  )
}
