import notebook from '../scss/notebook.module.scss';
import edgeTop from '../images/note-edge-top.png';
export default function NoteSection({children}) {
  return (
    <section className={notebook.noteSection}>
        <img src={edgeTop} alt="" className={notebook.noteDivider}/>
        <div className={notebook.noteContent}>{children}</div>
    </section>
  )
}
