import React, {useState} from 'react';
import {BrowserRouter, Routes, Route, Navigate} from 'react-router-dom';

import NavBar from './notebook/computer-science/common/navBar';
import Vol1no1 from './notebook/computer-science/algorithms-&-data-structures/pages/vol1no1'
import Vol1no2 from './notebook/computer-science/algorithms-&-data-structures/pages/vol1no2'
import Vol1no3 from './notebook/computer-science/algorithms-&-data-structures/pages/vol1no3'
import Vol1no4 from './notebook/computer-science/algorithms-&-data-structures/pages/vol1no4'
import Vol2no1 from './notebook/computer-science/computer-&-mobile-networks/pages/vol2no1'
import Vol6no1 from './notebook/computer-science/AI-&-machine-learning/pages/vol6no1'


function App() {
  const [projectDropdown, setProjectDropdown] = useState(false)
  const [menuDropdown, setMenuDropdown] = useState(false)
  function handleDropdownClose() {
    setMenuDropdown(false)
    setProjectDropdown(false)
  }
  return (
    <React.Fragment>
      <NavBar projectDropdown={projectDropdown} setProjectDropdown={setProjectDropdown} menuDropdown={menuDropdown} setMenuDropdown={setMenuDropdown} />
    <BrowserRouter>
      <Routes>
        <Route path='/notebook/computer-science/AI-&-machine-learning/vol6no1' element={<Vol6no1 onDropdownClose={handleDropdownClose}/>} />
        <Route path='/notebook/computer-science/computer-&-mobile-networks/vol2no1' element={<Vol2no1 onDropdownClose={handleDropdownClose}/>} />
        <Route path='/notebook/computer-science/algorithms-&-data-structures/vol1no4' element={<Vol1no4 onDropdownClose={handleDropdownClose}/>} />
        <Route path='/notebook/computer-science/algorithms-&-data-structures/vol1no3' element={<Vol1no3 onDropdownClose={handleDropdownClose}/>} />
        <Route path='/notebook/computer-science/algorithms-&-data-structures/vol1no2' element={<Vol1no2 onDropdownClose={handleDropdownClose}/>} />
        <Route path='/notebook/computer-science/algorithms-&-data-structures/vol1no1' element={<Vol1no1 onDropdownClose={handleDropdownClose}/>} />
        <Route path='/notebook' element={<Vol1no1 onDropdownClose={handleDropdownClose}/>} />
        <Route path="/" element={<Navigate replace to="/notebook" />} />
      </Routes>
    </BrowserRouter>

  </React.Fragment>
  );
}

export default App;
