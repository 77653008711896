import React from 'react';
import { Helmet } from 'react-helmet';
//components

import Newpaper from '../../common/newspaper';
import NoteSectionStart from '../../common/noteSectionStart';
import NoteSection from '../../common/noteSection';
import NoteSectionEnd from '../../common/noteSectionEnd';
//images
// import figure1_1 from '../algorithms-&-data-structures/images/INSERTION-SORT(A).png';
// import figure2_1 from '../algorithms-&-data-structures/images/RAM-model.png';
// import figure2_2 from '../algorithms-&-data-structures/images/LINEAR-SEARCH (A, n, x).png';
// import figure3_1 from '../algorithms-&-data-structures/images/Stacks.png';
// import figure3_2 from '../algorithms-&-data-structures/images/Stacks-Operations.png';
// import figure3_3 from '../algorithms-&-data-structures/images/Queues.png';
// import figure3_4 from '../algorithms-&-data-structures/images/Queues-Operations.png';
// import figure3_5 from '../algorithms-&-data-structures/images/Linked-Lists.png';
// import figure3_6 from '../algorithms-&-data-structures/images/LIST-SEARCH(L, k).png';
// import figure3_7 from '../algorithms-&-data-structures/images/LIST-INSERT(L, x).png';
// import figure3_8 from '../algorithms-&-data-structures/images/LIST-DELETE(L, x).png';
// import figure4_1 from '../algorithms-&-data-structures/images/free-tree.png';
// import figure4_2 from '../algorithms-&-data-structures/images/ordered-tree.png';
// import figure4_3 from '../algorithms-&-data-structures/images/binary-tree.png';
// import figure4_4 from '../algorithms-&-data-structures/images/complete-binary-tree.png';


//Edit
import newspaper from '../images/newspaper-no4.png'
import newspaperHeading from '../images/no4.png'
const issueNo = 4
const volumeNo = 1
const module = 'algorithms-&-data-structures'

export default function Vol1no2() {
  return (
    <React.Fragment>
      <Helmet><title>{`CS: Vol.${volumeNo} No.${issueNo}`}</title></Helmet>



      <Newpaper newspaper={newspaper} newspaperHeading={newspaperHeading} volumeNo={volumeNo} link1={`/notebook/computer-science/${module}/vol${volumeNo}no1`} link2={`/notebook/computer-science/${module}/vol${volumeNo}no2`} link3={`/notebook/computer-science/${module}/vol${volumeNo}no3`} link4={`/notebook/computer-science/${module}/vol${volumeNo}no4`} />

      <NoteSectionStart> </NoteSectionStart>

      <NoteSection> </NoteSection>

      <NoteSectionEnd> </NoteSectionEnd>
    </React.Fragment>
  )
}


