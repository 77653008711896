import React from 'react'
import '../scss/dropdown.scss'
import { CgWebsite } from 'react-icons/cg';


export default function MenuDropdown() {
  return (
    <div className="menuDropdown dropdown">

    <div className="menuDropdownIcons">

    <a href='/notebook/computer-science/algorithms-&-data-structures/vol1no1'>
        <div>
          <CgWebsite className='menuDropdownIcon'/>
          <h4>Algorithms & Data Structures</h4>
          <hr />
        </div>
      </a>

    <a href='/notebook/computer-science/computer-&-mobile-networks/vol2no1'>
        <div>
          <CgWebsite className='menuDropdownIcon'/>
          <h4>Computer Network</h4>
          <hr />
        </div>

      </a>
      <a href='/notebook/computer-science/AI-&-machine-learning/vol6no1' >
        <div>
         <CgWebsite className='menuDropdownIcon'/>
          <h4>AI & Machine Learning</h4>
        </div>
      </a>
    </div>


  </div>
  )
}
