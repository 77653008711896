import React from 'react';
import { Helmet } from 'react-helmet';
//components

import Newpaper from '../../common/newspaper';
import NoteSectionStart from '../../common/noteSectionStart';
import NoteSection from '../../common/noteSection';
import NoteSectionEnd from '../../common/noteSectionEnd';
//images
// import figure1_1 from '../AI-&-machine-learning/images/';
// import figure2_1 from '../AI-&-machine-learning/images/';
// import figure2_2 from '../AI-&-machine-learning/images/';
// import figure3_1 from '../AI-&-machine-learning/images/';
// import figure3_2 from '../AI-&-machine-learning/images/';
// import figure3_3 from '../AI-&-machine-learning/images/';
// import figure3_4 from '../AI-&-machine-learning/images/';
// import figure3_5 from '../AI-&-machine-learning/images/';
// import figure3_6 from '../AI-&-machine-learning/images/';
// import figure3_7 from '../AI-&-machine-learning/images/;
// import figure3_8 from '../AI-&-machine-learning/images/';
// import figure4_1 from '../AI-&-machine-learning/images/';
// import figure4_2 from '../AI-&-machine-learning/images/';
// import figure4_3 from '../AI-&-machine-learning/images/';
// import figure4_4 from '../AI-&-machine-learning/images/';


//Edit
import newspaper from '../images/newspaper-no1.png'
import newspaperHeading from '../images/no1.png'
const issueNo = 1
const volumeNo = 6
const module = 'AI-&-machine-learning'

export default function Vol1no2() {
  return (
    <React.Fragment>
      <Helmet><title>{`CS: Vol.${volumeNo} No.${issueNo}`}</title></Helmet>


      <Newpaper newspaper={newspaper} newspaperHeading={newspaperHeading} volumeNo={volumeNo} link1={`/notebook/computer-science/${module}/vol${volumeNo}no1`} link2={`/notebook/computer-science/${module}/vol${volumeNo}no2`} link3={`/notebook/computer-science/${module}/vol${volumeNo}no3`} link4={`/notebook/computer-science/${module}/vol${volumeNo}no4`} />

      <NoteSectionStart> </NoteSectionStart>

      <NoteSection> </NoteSection>

      <NoteSectionEnd> </NoteSectionEnd>
    </React.Fragment>
  )
}


