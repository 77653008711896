import React from 'react';
import { Helmet } from 'react-helmet';
//components

import Newpaper from '../../common/newspaper';
import NoteSectionStart from '../../common/noteSectionStart';
import NoteSection from '../../common/noteSection';
import NoteSectionEnd from '../../common/noteSectionEnd';
//images
import figure1_1 from '../images/INSERTION-SORT(A).png';
import figure1_2 from '../images/insert-sort.png';
import figure1_3 from '../images/INSERTION-SORT(A)-Running-Time.png';
import figure2_1 from '../images/MERGE(A, p, q, r).png';
import figure2_2 from '../images/merge1.png';
import figure2_3 from '../images/merge2.png';
import figure2_4 from '../images/MERGE-SORT(A, p, r).png';
import figure2_5 from '../images/merge-sort.png';
import figure3_1 from '../images/max-heap.png';
import figure3_2 from '../images/MAX-HEAPIFY(A,i).png';
import figure3_3 from '../images/MAX-HEAPIFY(A,2).png';
import figure3_4 from '../images/BUILD-MAX-HEAP(A).png';
import figure3_5 from '../images/build-max-heap-operation.png';
import figure3_6 from '../images/HEAPSORT(A).png';
import figure3_7 from '../images/heapsort-operation.png';
import figure4_1 from '../images/QUICKSORT(A, p, r).png';
import figure4_2 from '../images/PARTITION(A, p, r).png';
import figure4_3 from '../images/partition-operation.png';
import figure4_4 from '../images/partition-operation2.png'
import figure4_5 from '../images/partition-operation3.png';
import figure4_6 from '../images/quicksort-recursion-tree.png';
import figure4_7 from '../images/quicksort-recursion-tree2.png';


//Edit
import newspaper from '../images/newspaper-no2.png'
import newspaperHeading from '../images/no2.png'
const issueNo = 2
const volumeNo = 1
const module = 'algorithms-&-data-structures'

export default function Vol1no2(props) {
  return (
    <section onClick={props.onDropdownClose}>
      <Helmet><title>{`CS: Vol.${volumeNo} No.${issueNo}`}</title></Helmet>

      <Newpaper newspaper={newspaper} newspaperHeading={newspaperHeading} volumeNo={volumeNo} link1={`/notebook/computer-science/${module}/vol${volumeNo}no1`} link2={`/notebook/computer-science/${module}/vol${volumeNo}no2`} link3={`/notebook/computer-science/${module}/vol${volumeNo}no3`} link4={`/notebook/computer-science/${module}/vol${volumeNo}no4`} />

      <NoteSectionStart>
      <h1>Learning Outcomes</h1>
        <ul>
          <li>write sorting algorithms and demonstrate how they work</li>
          <li>analyse the performance of the sorting algorithms</li>
          <li>compare the performance of the sorting algorithms</li>
        </ul>

        <h2>Insert Sort</h2>
        <p>
          Insertion sort is an efficient algorithm for sorting a small number of
          elements. Insertion sort works the way many people sort a hand of
          playing cards. We start with an empty left hand and the cards face
          down on the table. We then remove one card at a time from the table
          and insert it into the correct position in the left hand. To find the
          correct position for a card, we compare it with each of the cards
          already in the hand, from right to left. At all times, the cards held
          in the left hand are sorted, and these cards were originally the top
          cards of the pile on the table.
        </p>
        <p>
          Figure 1.1 presents the pseudocode for insertion sort as a procedure
          called INSERTION-SORT, which takes as a parameter an array A[1 ... n]
          containing a sequence of length n that is to be sorted. (In the code,
          the number n of elements in A is denoted by A.length.) The algorithm
          sorts the input numbers in place: it rearranges the numbers within the
          array A, with at most a constant number of them stored outside the
          array at any time. The input array A contains the sorted output
          sequence when the INSERTION-SORT procedure is finished.
        </p>
        <figure>
          <img
            src={figure1_1}
            alt=""
          />
          <figcaption><strong>Figure 1.1 </strong> Insert-Sort(A)</figcaption>
        </figure>
        <ul>
          <li>
            Line 1 loops through the elements in the initial right subarray.
          </li>
          <li>Line 2 sets the element as key.</li>
          <li>Line 3 is a comment.</li>
          <li>
            Line 4 works out the length for the left subarray i, initially as 1.
          </li>
          <li>
            Line 5 evaluates whether a move is needed by comparing key with the
            element in the left subarray A[i].
          </li>
          <li>Line 6 moves A[i] one position to the right.</li>
          <li>
            Line 7 set the scene for comparing key with the next element in the
            left subarray A[i-1].
          </li>
          <li>Line 8 inserts key to the place as a result of the shuffling.</li>
        </ul>
        <h3>Loop invariants and the correctness of insertion sort</h3>
        <p>
          Figure 1.2 shows how this algorithm works for A = [5, 2, 4, 6, 1, 3].
          Array indices appear above the rectangles, and values stored in the
          array positions appear within the rectangles. (a)-(e) The iterations
          of the for loop of lines 1-8. In each iteration, the black rectangle
          holds the key taken from A[j], which is compared with the values in
          shaded rectangles to its left in the test of line 5. Shaded arrows
          show array values moved one position to the right in line 6, and black
          arrows indicate where the key moves to in line 8. (f) The final sorted
          array.
        </p>

        <figure>
          <img
            src={figure1_2}
            alt=""
          />
          <figcaption>
            <strong>Figure 1.2 </strong> The operation of INSERTION-SORT on the
            array A = [5, 2, 4, 6, 1, 3]
          </figcaption>
        </figure>
        <p>
          In Figure 1.1, The index j indicates the “current card” being inserted
          into the hand. At the beginning of each iteration of the for loop,
          which is indexed by j , the subarray consisting of elements A[1 ... j
          - 1] constitutes the currently sorted hand, and the remaining subarray
          A[j + 1 ... n] corresponds to the pile of cards still on the table. In
          fact, elements A[1 ... j - 1] are the elements originally in positions
          1 through j - 1, but now in sorted order. We state these properties of
          A[1 ... j - 1] formally as a <strong>loop invariant</strong>:
        </p>
        <p>
          <strong>Initialization:</strong> Subarray A[1, . . . , j - 1]​​​ is
          sorted. subarray A[j, . . . , n] is unsorted.
        </p>
        <p>
          <strong>Maintenance:</strong> Informally, the body of the for loop
          works by moving A[j - 1], A[j - 2], A[j - 3], and so on by one
          position to the right until it finds the proper position for A[j]
          (lines 4-7), at which point it inserts the value of A[j] (line 8). The
          subarray A[1 . . . j] then consists of the elements originally in A[1
          . . . j], but in sorted order. Incrementing j for the next iteration
          of the for loop then preserves the loop invariant.
        </p>
        <p>
          <strong>Termination:</strong> The condition causing the for loop to
          terminate is that j &gt A:length = n. As each loop iteration increases j
          by 1, while j = n + 1 loop terminates . Substituting n + 1 for j to
          have the subarray A[1 . . . n] consists of the elements originally in
          A[1 . . . n], but in sorted order.
        </p>

        <h3>Analysis of insertion sort</h3>
        <p>
          The time taken by the INSERTION-SORT procedure depends on the input:
          sorting a thousand numbers takes longer than sorting three numbers.
          Moreover, INSERTION- SORT can take different amounts of time to sort
          two input sequences of the same size depending on how nearly sorted
          they already are. In general, the time taken by an algorithm grows
          with the size of the input, so it is traditional to describe the
          running time of a program as a function of the size of its input.
        </p>
        <p>
          The best notion for <strong>input size</strong> depends on the problem
          being studied. For many problems, such as sorting or computing
          discrete Fourier transforms, the most natural measure is the number of
          items in the input—for example, the array size n for sorting. For many
          other problems, such as multiplying two integers, the best measure of
          input size is the total number of bits needed to represent the input
          in ordinary binary notation. Sometimes, it is more appropriate to
          describe the size of the input with two numbers rather than one. For
          instance, if the input to an algorithm is a graph, the input size can
          be described by the numbers of vertices and edges in the graph.
        </p>
        <p>
          The running time of an algorithm on a particular input is the number
          of primitive operations or “steps” executed. It is convenient to
          define the notion of step so that it is as machine-independent as
          possible. A constant amount of time is required to execute each line
          of our pseudocode. One line may take a different amount of time than
          another line, but we shall assume that each execution of the ith line
          takes time ci, where ci is a constant. This viewpoint is in keeping
          with the RAM model, and it also reflects how the pseudocode would be
          implemented on most actual computers.
        </p>
        <figure>
          <img
            src={figure1_3}
            alt=""
          />
          <figcaption>
            <strong>Figure 1.3 </strong> The running time of INSERTION-SORT(A)
          </figcaption>
        </figure>
        <ul>
          <li>Line 1 will be executed n times (when j=2, 3…n, n+1).</li>
          <li>Line 2 & 4 will be executed n-1 times (when j=2, 3…n).</li>
          <li>
            Line 5: This is the worst case situation, the last paragraph of page
            26 explains this. ∑ <sup>n</sup><sub>j=2</sub> t<sub>j</sub> is
            equivalent to t2 + t3 +… + tn. tn refers to the worst case execution
            times when j=n, line 5 will be evaluated n times (when i = n - 1, n
            - 2 ... 2, 1, 0).
          </li>
          <li>
            Line 6 & 7: their execution times is one time short of line 5 in
            each cycle due to the final evaluation in line 5 (when i = 0) will
            always be false.
          </li>
          <li>
            Line 8 is within the outer loop but outside the inner loop so it
            will be executed n - 1 times, the same as line 2&3.
          </li>
        </ul>
        <p>
          As shown in Figure 1.3, the running time of the algorithm is the sum
          of running times for each statement executed; a statement that takes
          c<sub>i</sub> steps to execute and executes n times will contribute
          c<sub>i</sub> * n to the total running time.
        </p>
        <p>
          The best case occurs if the array is already sorted. For each j = 2,
          3, ... , n, we then find that A[i] &le; key in line 5 when i has its
          initial value of j-1. Thus t<sub>j</sub> = 1 for j = 2, 3, ... , n,
          and the best-case running time is T(n) = c<sub>1</sub> * n + c<sub
            >2</sub
          >
          * (n - 1) + c<sub>4</sub> * (n - 1) + c<sub>5</sub> * (n - 1) + c<sub
            >8</sub
          >
          * (n - 1) = (c<sub>1</sub> + c<sub>2</sub> + c<sub>4</sub> + c<sub
            >5</sub
          >
          + c<sub>8</sub>) * n - (c<sub>2</sub> + c<sub>4</sub> + c<sub>5</sub>
          + c<sub>8</sub>), which is T(n)= a * n - b, so the best case execution
          time is Ө(n).
        </p>
        <p>
          If the array is in reverse sorted order—that is, in decreasing
          order—the worst case results. We must compare each element A[j] with
          each element in the entire sorted subarray A[1 ... j-1],and so t<sub
            >j</sub
          >
          = j for j = 2, 3, ... , n. Noting that ∑ <sup>n</sup><sub>j=2</sub> j
          = [n * (n + 1) / 2] - 1, ∑ <sup>n</sup><sub>j=2</sub> j - 1 = n * (n -
          1) / 2, thus the worse case running time of INSERT-SORT(A) is T(n) = a
          * n<sup>2</sup> + b * n + c, so the worse case execution time is the
          Ө(n<sup>2</sup>).
        </p>
        <p>
          Having worked out the best and worst case performance, the time
          complexity of INSERT-SORT(A) is O(n<sup>2</sup>) - no more than some
          constant of n<sup>2</sup>, Ω(n) - no less than some constant of n.
        </p>
      
      </NoteSectionStart>

      <NoteSection>
      <h2>Merge Sort</h2>
        <p>
          The merge sort algorithm follows a divide and conquer approach, which
          breaks the problem into several subproblems that are similar to the
          original problem but smaller in size, solve the subproblem
          recursively, and then combine these solutions to create a solution to
          the original problem.
        </p>
        <h3>The divide and conquer approach</h3>
        <p>
          Many useful algorithms are recursive in structure: to solve a given
          problem, they call themselves recursively one or more times to deal
          with closely related sub-problems. These algorithms typically follow a
          divide-and-conquer approach: they break the problem into several
          subproblems that are similar to the original problem but smaller in
          size, solve the subproblems recursively, and then combine these
          solutions to create a solution to the original problem.
        </p>
        <p>
          The divide-and-conquer paradigm involves three steps at each level of
          the recursion:
        </p>
        <ul>
          <li>
            <strong>Divide</strong> the problem into a number of subproblems
            that are smaller instances of the same problem.
          </li>
          <li>
            <strong>Conquer</strong> the subproblems by solving them
            recursively. If the subproblem sizes are small enough, however, just
            solve the subproblems in a straightforward manner.
          </li>
          <li>
            <strong>Combine</strong> the solutions to the subproblems into the
            solution for the original problem.
          </li>
        </ul>
        <p>
          The <strong>merge sort</strong> algorithm closely follows the
          divide-and-conquer paradigm. Intuitively, it operates as follows.
        </p>
        <ul>
          <li>
            <strong>Divide:</strong> Divide the n-element sequence to be sorted
            into two subsequences of n=2 elements each.
          </li>
          <li>
            <strong>Conquer:</strong> Sort the two subsequences recursively
            using merge sort.
          </li>
          <li>
            <strong>Combine:</strong> Merge the two sorted subsequences to
            produce the sorted answer.
          </li>
        </ul>
        <p>
          The recursion “bottoms out” when the sequence to be sorted has length
          1, in which case there is no work to be done, since every sequence of
          length 1 is already in sorted order.
        </p>
        <p>
          The key operation of the merge sort algorithm is the merging of two
          sorted sequences in the “combine” step. Mergng by calling an auxiliary
          procedure MERGE(A, p, q, r), where A is an array and p, q, and r are
          indices into the array such that p &le; q &lt; r. The procedure assumes
          that the subarrays A[p, . . ,q] and A[q + 1, . . , r] are in sorted
          order. It merges them to form a single sorted subarray that replaces
          the current subarray A[p, . . ,r].
        </p>
        <p>
          Our MERGE procedure takes time Θ(n), where n = r - p + 1 is the total
          number of elements being merged, and it works as follows. Returning to
          the cardplaying motif, suppose we have two piles of cards face up on a
          table. Each pile is sorted, with the smallest cards on top. We wish to
          merge the two piles into a single sorted output pile, which is to be
          face down on the table. Our basic step consists of choosing the
          smaller of the two cards on top of the face-up piles, removing it from
          its pile (which exposes a new top card), and placing this card face
          down onto the output pile. We repeat this step until one input pile is
          empty, at which time we just take the remaining input pile and place
          it face down onto the output pile. Computationally, each basic step
          takes constant time, since we are comparing just the two top cards.
          Since we perform at most n basic steps, merging takes Θ(n) time.
        </p>
        <p>
          The Figure 2.1 pseudocode implements the above idea, but with an
          additional twist that avoids having to check whether either pile is
          empty in each basic step. We place on the bottom of each pile a
          sentinel card, which contains a special value that we use to simplify
          our code. Here, we use &#8734; as the sentinel value, so that whenever a
          card with &#8734; is exposed, it cannot be the smaller card unless both
          piles have their sentinel cards exposed. But once that happens, all
          the nonsentinel cards have already been placed onto the output pile.
          Since we know in advance that exactly r - p + 1 cards will be placed
          onto the output pile, we can stop once we have performed that many
          basic steps.
        </p>

        <figure>
          <img
            src={figure2_1}
            alt=""
          />
          <figcaption>
            <strong>Figure 2.1 </strong> Merge(A, p, q, r)
          </figcaption>
        </figure>
        <p>
          Line 1 computes the length n<sub>1</sub> of the subarray A[p, . . ,q],
          and line 2 computes the length n<sub>2</sub> of the subarray A[q+1, .
          . ,r].
        </p>
        <p>
          In line 3, create arrays L and R(“left”and“right”),of lengths n<sub
            >1</sub
          >
          + 1 and n<sub>2</sub> + 1, the extra position in each array will hold
          the sentinel.
        </p>
        <p>
          The <strong>for</strong> loop of lines 4-5 copies the subarray A[p, .
          . ,q] into L[1, ... ,n<sub>1</sub>], and the <strong>for</strong> loop
          of lines 6-7 copies the subarray A[q + 1, ... ,r] into R[1, ... ,
          n<sub>2</sub>].
        </p>
        <p>Lines 8-9 put the sentinels at the ends of the arrays L and R.</p>
        <p>
          Lines 10-17, illustrated in Figure 2.2, perform the r - p + 1 basic
          steps by maintaining the following loop invariant: <br />
          At the start of each iteration of the for loop of lines 12-17, the
          subarray A[p, ... , k - 1] contains the k - p smallest elements of
          L[1, ... ,n<sub>1</sub> + 1] and R[1, ... , n<sub>2</sub> + 1], in
          sorted order. Moreover, L[i] and R[j] are the smallest elements of
          their arrays that have not been copied back into A.
        </p>
        <p>
          We must show that this loop invariant holds prior to the first
          iteration of the for loop of lines 12-17, that each iteration of the
          loop maintains the invariant, and that the invariant provides a useful
          property to show correctness when the loop terminates.
        </p>
        <p>
          <strong>Initialization:</strong> Prior to the first iteration of the
          loop, as k = p, so that the subarray A[p, ... , k - 1] is empty. This
          empty subarray contains the k - p = 0 smallest elements of L and R,
          and since i = j = 1, both L[i] and R[j] are the smallest elements of
          their arrays that have not been copied back into A.
        </p>

        <p>
          <strong>Maintenance:</strong> To see that each iteration maintains the
          loop invariant, let us first suppose that L[i] &le; R[j]. Then L[i] is
          the smallest element not yet copied back into A. Because A[p, ... , k
          - 1] contains the k - p smallest elements, after line 14 copies L[i]
          into A[k], the subarray A[p, ... , k] will contain the k - p + 1
          smallest elements. Incrementing k (in the for loop update) and i (in
          line 15) reestablishes the loop invariant for the next iteration. If
          instead L[i] &gt; R[j], then lines 16-17 perform the appropriate action
          to maintain the loop invariant.
        </p>
        <p>
          <strong>Termination:</strong> At termination, k = r + 1. By the loop
          invariant, the subarray A[p, ... , k - 1], which is A[p, ... , r],
          contains the k - p = r - p + 1 smallest elements of L[1, ... , n<sub
            >1</sub
          >
          + 1] and R[1, ... , n<sub>2</sub> + 1], in sorted order. The arrays L
          and R together contain n<sub>1</sub> + n<sub>2</sub> + 2 = r - p + 3
          elements. All but the two largest have been copied back into A, and
          these two largest elements are the sentinels.
        </p>

        <figure>
          <img src={figure2_2} alt="" />
          <figcaption>
            <strong>Figure 2.2 </strong> The operation of lines 10-17 in the
            call MERGE(A, 9, 12, 16) when the subarray A[9 ... 16] contains the
            sequence [2, 4, 5, 7, 1, 2, 3, 6]
          </figcaption>
        </figure>
        <figure>
          <img src={figure2_3} alt="" />
          <figcaption><strong>Figure 2.3 </strong> Continued</figcaption>
        </figure>
        <p>
          In Figure 2.2, After copying and inserting sentinels, the array L
          contains [2, 4, 5, 7, &#8734;], and the array R contains [1, 2, 3, 6, &#8734;].
          Lightly shaded positions in A contain their final values, and lightly
          shaded positions in L and R contain values that have yet to be copied
          back into A. Taken together, the lightly shaded positions always
          comprise the values originally in A[9 ... 16], along with the two
          sentinels. Heavily shaded positions in A contain values that will be
          copied over, and heavily shaded positions in L and R contain values
          that have already been copied back into A. (a)-(h) The arrays A, L,
          and R, and their respective indices k, i, and j prior to each
          iteration of the loop of lines 12-17.
        </p>
        <p>
          In Figure 2.3 (i), the arrays and indices at termination. At this
          point, the subarray in A[9 ... 16] is sorted, and the two sentinels in
          L and R are the only two elements in these arrays that have not been
          copied into A.
        </p>
        <p>To see that</p>
        <ul>
          <li>MERGE procedure runs in Θ(n) time, where n = r - p + 1</li>
          <li>Lines 1-3 and 8-11 takes constant time</li>
          <li>
            The <strong>for</strong> loops of lines 4-7 take Θ(n<sub>1</sub> +
            n<sub>2</sub>) = Θ(n) time,
          </li>
          <li>
            There are n iterations of the for loop of lines 12-17, each of which
            takes constant time.
          </li>
        </ul>
        <p>
          Use the MERGE procedure as a subroutine in the merge sort algorithm.
          As shown in Figure 2.4, the procedure MERGE-SORT(A, p, r) sorts the
          elements in the subaray A[p, . . ,r]. If p &ge; r, the subarray has at
          most one element and is therefore already sorted. Otherwise, the
          divide step simply computes an index q that par- titions A[p, . . ,r]
          into two subarrays: A[p, . . ,q], containing [n/2] elements, and A[q +
          1, . . ,r], containing [n/2] elements.
        </p>
        <figure>
          <img
            src={figure2_4}
            alt=""
          />
          <figcaption>
            <strong>Figure 2.4 </strong> Merge-Sort(A, p, r)
          </figcaption>
        </figure>
        <p>
          To sort the entire sequence A = (A[1], A[2], ... , A[n]), we make the
          initial call MERGE-SORT(A, 1, A.length), where once again A.length =
          n. Figure 2.5 illustrates the operation of the procedure bottom-up
          (The lengths of the sorted sequences being merged increase as the
          algorithm progresses from bottom to top.) when n is a power of 2. The
          algorithm consists of merging pairs of 1-item sequences to form sorted
          sequences of length 2, merging pairs of sequences of length 2 to form
          sorted sequences of length 4, and so on, until two sequences of length
          n/2 are merged to form the final sorted sequence of length n.
        </p>
        <figure>
          <img
            src={figure2_5}
            alt=""
          />
          <figcaption>
            <strong>Figure 2.5 </strong> The operation of merge sort on the
            array A = [5, 2, 4, 7, 1, 3, 2, 6]
          </figcaption>
        </figure>
        <h3>Analyzing divide-and-conquer algorithms</h3>
        <p>
          When an algorithm contains a recursive call to itself, we can often
          describe its running time by a recurrence equation or recurrence,
          which describes the overall running time on a problem of size n in
          terms of the running time on smaller inputs. We can then use
          mathematical tools to solve the recurrence and provide bounds on the
          performance of the algorithm.
        </p>
        <p>
          A recurrence for the running time of a divide-and-conquer algorithm
          falls out from the three steps of the basic paradigm. As before, we
          let T(n) be the running time on a problem of size n. If the problem
          size is small enough, say n &le; c for some constant c, the
          straightforward solution takes constant time, which we write as Θ(1).
          Suppose that our division of the problem yields a subproblems, each of
          which is 1/b the size of the original. (For merge sort, both a and b
          are 2, but we shall see many divide-and-conquer algorithms in which a
          != b.) It takes time T(n/b) to solve one subproblem of size n/b, and
          so it takes time a * T(n/b) to solve a of them. If we take D(n) time
          to divide the problem into subproblems and C(n) time to combine the
          solutions to the subproblems into the solution to the original
          problem, we get the recurrence
        </p>
        <p>T(n) = Θ(1) if n &le; c</p>
        <p>T(n) = a * T(n / b) + D(n) + C(n) otherwise</p>
        <h3>Analysis of merge sort</h3>
        <p>
          Although the pseudocode for MERGE-SORT works correctly when the number
          of elements is not even, our recurrence-based analysis is simplified
          if we assume that the original problem size is a power of 2. Each
          divide step then yields two subsequences of size exactly n/2.
        </p>
        <p>
          We reason as follows to set up the recurrence for T(n), the worst-case
          running time of merge sort on n numbers. Merge sort on just one
          element takes constant time. When we have n &gt; 1 elements, we break
          down the running time as follows.
        </p>
        <p>
          <strong>Divide:</strong> The divide step just computes the middle of
          the subarray, which takes constant time. Thus, D(n) = Θ(1).
        </p>
        <p>
          <strong>Conquer:</strong> We recursively solve two subproblems, each
          of size n = 2, which contributes 2 * T(n / 2) to the running time.
        </p>
        <p>
          <strong>Combine:</strong> We have already noted that the MERGE
          procedure on an n-element subarray takes time Θ(n), and so C(n) =
          Θ(n).
        </p>
        <p>
          When we add the functions D(n) and C(n) for the merge sort analysis,
          we are adding a function that is Θ(n) and a function that is Θ(1).
          This sum is a linear function of n, that is, Θ(n). Adding it to the 2
          * T(n / 2) term from the “conquer” step gives the recurrence for the
          worst-case running time T(n) of merge sort:
        </p>
        <p>T(n) = Θ(1) if n = 1</p>
        <p>T(n) = 2 * T(n / 2) + Θ(n) if n &gt; 1</p>
      </NoteSection>

      <NoteSection>
      <h2>Heapsort</h2>
        <p>
          Like merge sort, but unlike insertion sort, heapsort's running time is
          O(nlgn). Like insertion sort, but unlike merge sort, heapsort sorts in
          place: only a constant number of array elements are stored outside the
          input array at any time. Thus, heapsort combines the better attributes
          of the two.
        </p>
        <h3>Heaps</h3>
        <p>
          The <strong>(binary) heap</strong> data structure is an array object
          that we can view as a nearly complete binary tree, as shown in Figure
          3.1. Each node of the tree corresponds to an element of the array. The
          tree is completely filled on all levels except possibly the lowest,
          which is filled from the left up to a point. An array A that
          represents a heap is an object with two attributes: A.length, which
          (as usual) gives the number of elements in the array, and A.heap-size,
          which represents how many elements in the heap are stored within array
          A. That is, although A[1 ... A.length] may contain numbers, only the
          elements in A[1 ... A.heap-size], where 0 &le; A.heap-size &le; A.length,
          are valid elements of the heap. The root of the tree is A[1], and
          given the index i of a node, we can easily compute the indices of its
          parent, left child, and right child:
        </p>
        <pre>
          PARENT(i)
            return [i / 2]

          LEFT(i)
            return [2 * i]

          RIGHT(i)
            return [2 * i + 1]
        </pre>
        <p>
          In Figure 3.1, The number within the circle at each node in the tree
          is the value stored at that node. The number above a node is the
          corresponding index in the array. Above and below the array are lines
          showing parent-child relationships; parents are always to the left of
          their children. The tree has height three; the node at index 4 (with
          value 8) has height one.
        </p>
        <figure>
          <img src={figure3_1} alt="" />
          <figcaption>
            <strong>Figure 3.1 </strong> A max-heap viewed as (a) a binary tree
            and (b) an array
          </figcaption>
        </figure>
        <p>
          On most computers, the LEFT procedure can compute 2i in one
          instruction by simply shifting the binary representation of i left by
          one bit position. Similarly, the RIGHT procedure can quickly compute
          2i + 1 by shifting the binary representation of i left by one bit
          position and then adding in a 1 as the low-order bit. The PARENT
          procedure can compute [i / 2] by shifting i right one bit position. Good
          implementations of heapsort often implement these procedures as
          “macros” or “inline” procedures. There are two kinds of binary heaps:
          max-heaps and min-heaps. In both kinds, the values in the nodes
          satisfy a heap property, the specifics of which depend on the kind of
          heap. In a max-heap, the max-heap property is that for every node i
          other than the root,
        </p>
        <p>A[PARENT(i)] &ge; A[i]</p>
        <p>
          that is, the value of a node is at most the value of its parent. Thus,
          the largest element in a max-heap is stored at the root, and the
          subtree rooted at a node contains values no larger than that contained
          at the node itself. A min-heap is organized in the opposite way; the
          min-heap property is that for every node i other than the root,
        </p>
        <p>A[PARENT(i)] &le; A[i]</p>
        <p>The smallest element in a min-heap is at the root.</p>
        <p>
          Viewing a heap as a tree, we define the height of a node in a heap to
          be the number of edges on the longest simple downward path from the
          node to a leaf, and we define the height of the heap to be the height
          of its root. Since a heap of n ele- ments is based on a complete
          binary tree, its height is Θ(lgn). The basic operations on heaps run
          in time at most proportional to the height of the tree and thus take
          O(lgn) time.
        </p>
        <ul>
          <li>
            The MAX-HEAPIFY procedure, which runs in O(lgn) time, is the key to
            maintaining the max-heap property.
          </li>
          <li>
            The BUILD-MAX-HEAP procedure, which runs in linear time, produces a
            max-heap from an unordered input array.
          </li>
          <li>
            The HEAPSORT procedure, which runs in O(nlgn) time, sorts an array
            in place.
          </li>
          <li>
            The MAX-HEAP-INSERT, HEAP-EXTRACT-MAX, HEAP-INCREASE-KEY, and
            HEAP-MAXIMUM procedures, which run in O(lgn) time, allow the heap
            data structure to implement a priority queue.
          </li>
        </ul>
        <h3>Maintaining the heap property</h3>
        <p>
          In order to maintain the max-heap property, we call the procedure
          MAX-HEAPIFY. Its inputs are an array A and an index i into the array.
          When it is called, MAX- HEAPIFY assumes that the binary trees rooted
          at LEFT(i) and RIGHT(i) are max-heaps, but that A[i] might be smaller
          than its children, thus violating the max-heap property. MAX-HEAPIFY
          lets the value at A[i] “float down” in the max-heap so that the
          subtree rooted at index i obeys the max-heap property, as showin in
          Figure 3.2.
        </p>
        <figure>
          <img
            src={figure3_2}
            alt=""
          />
          <figcaption><strong>Figure 3.2 </strong> Max-Heapify(A,i)</figcaption>
        </figure>
        <p>
          Figure 3.3 illustrates the action of MAX-HEAPIFY. At each step, the
          largest of the elements A[i], A[LEFT(i)], and A[RIGHT(i)] is
          determined, and its index is stored in largest. If A[i] is largest,
          then the subtree rooted at node i is already a max-heap and the
          procedure terminates. Otherwise, one of the two children has the
          largest element, and A[i] is swapped with A[largest], which causes
          node i and its children to satisfy the max-heap property. The node
          indexed by largest, however, now has the original value A[i], and thus
          the subtree rooted at largest might violate the max-heap property.
          Consequently, we call MAX-HEAPIFY recursively on that subtree.
        </p>
        <figure>
          <img
            src={figure3_3}
            alt=""
          />
          <figcaption>
            <strong>Figure 3.3 </strong> The action of MAX-HEAPIFY(A, 2), where
            A.heap-size = 10
          </figcaption>
        </figure>
        <p>
          In Figure 3.3, (a) The initial con- figuration, with A[2] at node i =
          2 violating the max-heap property since it is not larger than both
          children. The max-heap property is restored for node 2 in (b) by
          exchanging A[2] with A[4], which destroys the max-heap property for
          node 4. The recursive call MAX-HEAPIFY(A, 4) now has i = 4. After
          swapping A[4] with A[9], as shown in (c), node 4 is fixed up, and the
          recursive call MAX-HEAPIFY(A, 9) yields no further change to the data
          structure.
        </p>
        <p>
          The running time of MAX-HEAPIFY on a subtree of size n rooted at a
          given node i is the Θ(1) time to fix up the relationships among the
          elements A[i], A[LEFT(i)], and A[RIGHT(i)], plus the time to run
          MAX-HEAPIFY on a subtree rooted at one of the children of node i
          (assuming that the recursive call occurs). The children's subtrees
          each have size at most 2 * n / 3—the worst case occurs when the bottom
          level of the tree is exactly half full—and therefore we can describe
          the running time of MAX-HEAPIFY by the recurrence
        </p>
        <p>T(n) &le; T(2 * n / 3) + Θ(1)</p>
        <p>
          So T(n) = O(lgn), alternatively, we can characterize the running time
          of MAX-HEAPIFY on a node of height h as O(h).
        </p>
        <h3>Building a heap</h3>
        <p>
          The procedure BUILD-MAX-HEAP goes through the remaining nodes of the
          tree and runs MAX-HEAPIFY on each one, as shown in Figure 3.4.
        </p>
        <figure>
          <img
            src={figure3_4}
            alt=""
          />
          <figcaption>
            <strong>Figure 3.4 </strong> Build-Max-Heap(A)
          </figcaption>
        </figure>
        <p>
          Figure 3.5 shows an example of the action of BUILD-MAX-HEAP. To show
          why BUILD-MAX-HEAP works correctly, we use the following loop
          invariant: At the start of each iteration of the for loop of lines
          2-3, each node i + 1, i + 2, ... , n is the root of a max-heap.
        </p>
        <figure>
          <img
            src={figure3_5}
            alt=""
          />
          <figcaption>
            <strong>Figure 3.5 </strong> The operation of BUILD-MAX-HEAP,
            showing the data structure before the call to MAX-HEAPIFY in line 3
            of BUILD-MAX-HEAP.
          </figcaption>
        </figure>
        <p>
          In Figure 3.5, (a) A 10-element input array A and the bi- nary tree it
          represents. The figure shows that the loop index i refers to node 5
          before the call MAX-HEAPIFY(A, i). (b) The data structure that
          results. The loop index i for the next iteration refers to node 4.
          (c)-(e) Subsequent iterations of the for loop in BUILD-MAX-HEAP.
          Observe that whenever MAX-HEAPIFY is called on a node, the two
          subtrees of that node are both max-heaps. (f) The max-heap after
          BUILD-MAX-HEAP finishes.
        </p>
        <p>
          We need to show that this invariant is true prior to the first loop
          iteration, that each iteration of the loop maintains the invariant,
          and that the invariant provides a useful property to show correctness
          when the loop terminates.
        </p>
        <p>
          <strong>Initialization:</strong> Prior to the first iteration of the
          loop, i = [n / 2]. Each node [n / 2] + 1, [n / 2] + 2, ... , n is a
          leaf and is thus the root of a trivial max-heap.
        </p>
        <p>
          <strong>Maintenance:</strong> To see that each iteration maintains the
          loop invariant, observe that the children of node i are numbered
          higher than i. By the loop invariant, therefore, they are both roots
          of max-heaps. This is precisely the condition required for the call
          MAX-HEAPIFY(A, i) to make node i a max-heap root. Moreover, the
          MAX-HEAPIFY call preserves the property that nodes i + 1, i + 2, ... ,
          n are all roots of max-heaps. Decrementing i in the for loop update
          reestablishes the loop invariant for the next iteration.
        </p>
        <p>
          <strong>Termination:</strong> At termination, i = 0. By the loop
          invariant, each node 1, 2, ... , n is the root of a max-heap. In
          particular, node 1 is.
        </p>
        <p>
          We can compute a simple upper bound on the running time of BUILD-MAX-
          HEAP as follows. Each call to MAX-HEAPIFY costs O(lgn) time, and
          BUILD-MAX-HEAP makes O(n) such calls. Thus, the running time is
          O(nlgn). This upper bound, though correct, is not asymptotically
          tight.
        </p>
        <p>
          We can derive a tighter bound by observing that the time for
          MAX-HEAPIFY to run at a node varies with the height of the node in the
          tree, and the heights of most nodes are small. Our tighter analysis
          relies on the properties that an n-element heap has height [lgn] and
          at most [n / 2<sup>h+1</sup>] nodes of any height h.
        </p>
        <p>
          The time required by MAX-HEAPIFY when called on a node of height h is
          O(h), and so we can express the total cost of BUILD-MAX-HEAP as being
          bounded from above by O(n), Hence, we can build a max-heap from an
          unordered array in linear time.
        </p>
        <h3>The heapsort algorithm</h3>
        <p>
          The heapsort algorithm starts by using BUILD-MAX-HEAP to build a
          max-heap on the input array A[1 ... n], where n = A.length. Since the
          maximum element of the array is stored at the root A[1], we can put it
          into its correct final position by exchanging it with A[n]. If we now
          discard node n from the heap—and we can do so by simply decrementing
          A.heap-size—we observe that the children of the root remain max-heaps,
          but the new root element might violate the max-heap property. All we
          need to do to restore the max-heap property, however, is call
          MAX-HEAPIFY(A, 1), which leaves a max-heap in A[1 ... n - 1]. The
          heapsort algorithm then repeats this process for the max-heap of size
          n - 1 down to a heap of size 2, as shown in Figure 3.6
        </p>
        <figure>
          <img
            src={figure3_6}
            alt=""
          />
          <figcaption><strong>Figure 3.6 </strong> Heapsort(A)</figcaption>
        </figure>
        <p>
          Figure 3.7 shows an example of the operation of HEAPSORT after line 1
          has built the initial max-heap. The figure shows the max-heap before
          the first iteration of the for loop of lines 2-5 and after each
          iteration. The HEAPSORT procedure takes time O(nlgn), since the call
          to BUILD-MAX- HEAP takes time O(n) and each of the n - 1 calls to
          MAX-HEAPIFY takes time O(lgn).
        </p>
        <figure>
          <img
            src={figure3_7}
            alt=""
          />
          <figcaption>
            <strong>Figure 3.7 </strong> The operation of HEAPSORT
          </figcaption>
        </figure>
        <p>
          In Figure 3.7, (a) The max-heap data structure just after
          BUILD-MAX-HEAP has built it in line 1. (b)-(j) The max-heap just after
          each call of MAX-HEAPIFY in line 5, showing the value of i at that
          time. Only lightly shaded nodes remain in the heap. (k) The resulting
          sorted array A.
        </p>
      </NoteSection>

      <NoteSectionEnd>
      <h2>Quicksort</h2>
        <p>
          The quicksort algorithm has a worst-case running time of
          Θ(n<sup>2</sup>) on an input array of n numbers. Despite this slow
          worst-case running time, quicksort is often the best practical choice
          for sorting because it is remarkably efficient on the average: its
          expected running time is Θ(nlgn), and the constant factors hidden in
          the Θ(nlgn) notation are quite small. It also has the advantage of
          sorting in place, and it works well even in virtual-memory
          environments.
        </p>
        <h3>Description of quicksort</h3>
        <p>
          Quicksort, like merge sort, applies the divide-and-conquer paradigm.
          Here is the three-step divide-and-conquer process for sorting a
          typical subarray A[p ... r]:
        </p>
        <p>
          <strong>Divide:</strong> Partition (rearrange) the array A[p ... r]
          into two (possibly empty) subarrays A[p ... q - 1] and A[q+1 ... r] such
          that each element of A[p ... q - 1] is less than or equal to A[q], which
          is, in turn, less than or equal to each element of A[q + 1 ... r].
          Compute the index q as part of this partitioning procedure.
        </p>
        <p>
          <strong>Conquer:</strong> SortthetwosubarraysA[p ... q-1] and A[q+1
          ... r] by recursive calls to quicksort.
        </p>
        <p>
          <strong>Combine:</strong>
          Becausethesubarraysarealreadysorted,noworkisneededtocombine them: the
          entire array A[p ... r] is now sorted. The following procedure shown
          in Figure 4.1 implements quicksort:
        </p>
        <figure>
          <img
            src={figure4_1}
            alt=""
          />
          <figcaption>
            <strong>Figure 4.1 </strong> Quicksort(A, p, r)
          </figcaption>
        </figure>
        <p>
          To sort an entire array A, the initial call is QUICKSORT(A, 1,
          A.length).
        </p>
        <h4>Partitioning the array</h4>
        <p>
          The key to the algorithm is the PARTITION procedure, which rearranges
          the subarray A[p ... r] in place, as shown in Figure 4.2.
        </p>
        <figure>
          <img
            src={figure4_2}
            alt=""
          />
          <figcaption>
            <strong>Figure 4.2 </strong> Partition(A, p, r)
          </figcaption>
        </figure>
        <p>
          Figure 4.3 shows how PARTITION works on an 8-element array. PARTITION
          always selects an element x = A[r] as a <strong>pivot</strong> element
          around which to partition the subarray A[p ... r]. As the procedure
          runs, it partitions the array into four (possibly empty) regions. At
          the start of each iteration of the for loop in lines 3-6, the regions
          satisfy certain properties, shown in Figure 4.4. We state these
          properties as a loop invariant:
        </p>
        <p>
          At the beginning of each iteration of the loop of lines 3-6, for any
          array index k,
        </p>
        <ul>
          <li>If p &le; k &le; i, then A[k] &le; x.</li>
          <li>If i + 1 &le; k &le; j - 1, then A[k] &gt; x.</li>
          <li>If k = r, then A[k] = x.</li>
        </ul>
        <p>
          The indices between j and r - 1 are not covered by any of the three
          cases, and the values in these entries have no particular relationship
          to the pivot x.
        </p>
        <p>
          We need to show that this loop invariant is true prior to the first
          iteration, that each iteration of the loop maintains the invariant,
          and that the invariant provides a useful property to show correctness
          when the loop terminates.
        </p>
        <p>
          Initialization: Prior to the first iteration of the loop, i = p - 1
          and j = p. Because no values lie between p and i and no values lie
          between i + 1 and j - 1, the first two conditions of the loop
          invariant are trivially satisfied. The assign- ment in line 1
          satisfies the third condition.
        </p>
        <p>
          Maintenance: As Figure 4.6 shows, we consider two cases, depending on
          the outcome of the test in line 4. Figure 4.6(a) shows what happens
          when A[j] &lt; x; the only action in the loop is to increment j . After j
          is incremented, condition 2 holds for A[j - 1] and all other entries
          remain unchanged. Figure 4.6(b) shows what happens when A[j] &le; x; the
          loop increments i, swaps A[i] and A[j], and then increments j. Because
          of the swap, we now have that A[i] &le; x, and condition 1 is satisfied.
          Similarly, we also have that A[j - 1] &gt; x, since the item that was
          swapped into A[j - 1] is, by the loop invariant, greater than x.
        </p>
        <p>
          Termination: At termination, j D r. Therefore, every entry in the
          array is in one of the three sets described by the invariant, and we
          have partitioned the values in the array into three sets: those less
          than or equal to x, those greater than x, and a singleton set
          containing x.
        </p>

        <p>
          The final two lines of PARTITION finish up by swapping the pivot
          element with the leftmost element greater than x, thereby moving the
          pivot into its correct place in the partitioned array, and then
          returning the pivot’s new index. The output of PARTITION now satisfies
          the specifications given for the divide step. In fact, it satisfies a
          slightly stronger condition: after line 2 of QUICKSORT, A[q] is
          strictly less than every element of A[q + 1 ... r]. The running time
          of PARTITION on the subarray A[p ... r] is Θ(n), where n = r - p + 1.
        </p>
        <figure>
          <img
            src={figure4_3}
            alt=""
          />
          <figcaption>
            <strong>Figure 4.3 </strong> The operation of PARTITION on a sample
            array
          </figcaption>
        </figure>
        <p>
          In Figure 4.3, Array entry A[r] becomes the pivot element x. Lightly
          shaded array elements are all in the first partition with values no
          greater than x. Heavily shaded elements are in the second partition
          with values greater than x. The unshaded elements have not yet been
          put in one of the first two partitions, and the final white element is
          the pivot x. (a) The initial array and variable settings. None of the
          elements have been placed in either of the first two partitions. (b)
          The value 2 is “swapped with itself” and put in the partition of
          smaller values. (c)-(d) The values 8 and 7 are added to the partition
          of larger values. (e) The values 1 and 8 are swapped, and the smaller
          partition grows. (f) The values 3 and 7 are swapped, and the smaller
          partition grows. (g)-(h) The larger partition grows to include 5 and
          6, and the loop terminates. (i) In lines 7-8, the pivot element is
          swapped so that it lies between the two partitions.
        </p>
        <figure>
          <img
            src={figure4_4}
            alt=""
          />
          <figcaption>
            <strong>Figure 4.4 </strong> The four regions maintained by the
            procedure PARTITION on a subarray A[p ... r]
          </figcaption>
        </figure>
        <p>
          In Figure 4.4, the values in A[p ... i] are all less than or equal to
          x, the values in A[i + 1 ... j - 1] are all greater than x, and A[r] =
          x. The subarray A[j ... r - 1] can take on any values.
        </p>
        <figure>
          <img
            src={figure4_5}
            alt=""
          />
          <figcaption>
            <strong>Figure 4.5 </strong> The two cases for one iteration of
            procedure PARTITION
          </figcaption>
        </figure>
        <p>
          In Figure 4.5, (a) If A[j] &gt; x, the only action is to increment j ,
          which maintains the loop invariant. (b) If A[j] &le; x, index i is
          incremented, A[i] and A[j] are swapped, and then j is incremented.
          Again, the loop invariant is maintained.
        </p>
        <h3>Performance of quicksort</h3>
        <p>
          The running time of quicksort depends on whether the partitioning is
          balanced or unbalanced, which in turn depends on which elements are
          used for partitioning. If the partitioning is balanced, the algorithm
          runs asymptotically as fast as merge sort. If the partitioning is
          unbalanced, however, it can run asymptotically as slowly as insertion
          sort.
        </p>
        <h4>Worst-case partitioning</h4>
        <p>
          The worst-case behavior for quicksort occurs when the partitioning
          routine produces one subproblem with n - 1 elements and one with 0
          elements. Let us assume that this unbalanced partitioning arises in
          each recursive call. The partitioning costs Θ(n) time. Since the
          recursive call on an array of size 0 just returns, T(0) = Θ(1), and
          the recurrence for the running time is
        </p>
        <p>T(n) = T(n - 1) + T(0) + Θ(n) = T(n - 1) + Θ(n)</p>
        <p>
          Intuitively, if we sum the costs incurred at each level of the
          recursion, we get an arithmetic series, which evaluates to
          Θ(n<sup>2</sup>). Indeed, it is straightforward to use the
          substitution method to prove that the recurrence T(n) = T(n - 1) +
          Θ(n) has the solution T(n) = Θ(n<sup>2</sup>).
        </p>
        <p>
          Thus, if the partitioning is maximally unbalanced at every recursive
          level of the algorithm, the running time is Θ(n<sup>2</sup>).
          Therefore the worst-case running time of quicksort is no better than
          that of insertion sort. Moreover, the Θ(n<sup>2</sup>) running time
          occurs when the input array is already completely sorted—a common
          situation in which insertion sort runs in O(n) time.
        </p>
        <h4>Best-case partitioning</h4>
        <p>
          In the most even possible split, PARTITION produces two subproblems,
          each of size no more than n / 2, since one is of size [n / 2] and one of
          size [n / 2] - 1. In this case, quicksort runs much faster. The
          recurrence for the running time is then
        </p>
        <p>T(n) = 2 * T(n / 2) + Θ(n)</p>
        <p>
          where we tolerate the sloppiness from ignoring the floor and ceiling
          and from sub- tracting 1. Thus, this recurrence has the solution T(n)
          = Θ(nlgn). By equally balancing the two sides of the partition at
          every level of the recursion, we get an asymptotically faster
          algorithm.
        </p>
        <h4>Balanced partitioning</h4>
        <p>
          The average-case running time of quicksort is much closer to the best
          case than to the worst case. The key to understanding why is to
          understand how the balance of the partitioning is reflected in the
          recurrence that describes the running time.
        </p>
        <p>
          Suppose, for example, that the partitioning algorithm always produces
          a 9-to-1 proportional split, which at first blush seems quite
          unbalanced. We then obtain the recurrence
        </p>
        <p>T(n) = T(9 * n / 10)+ T(T / 10) + c * n</p>
        <p>
          on the running time of quicksort, where we have explicitly included
          the constant c hidden in the Θ(n) term. Figure 4.6 shows the recursion
          tree for this recurrence. Notice that every level of the tree has cost
          c * n, until the recursion reaches a bound- ary condition at depth
          log<sub>10</sub>n = Θ(lgn), and then the levels have cost at most c *
          n. The recursion terminates at depth log<sub>10/9</sub>n = Θ(lgn). The
          total cost of quick- sort is therefore O(nlgn). Thus, with a 9-to-1
          proportional split at every level of recursion, which intuitively
          seems quite unbalanced, quicksort runs in O(nlgn) time—asymptotically
          the same as if the split were right down the middle. Indeed, even a
          99-to-1 split yields an O(nlgn) running time. In fact, any split of
          constant proportionality yields a recursion tree of depth Θ(lgn),
          where the cost at each level is O(n). The running time is therefore
          O(nlgn) whenever the split has constant proportionality.
        </p>
        <figure>
          <img
            src={figure4_6}
            alt=""
          />
          <figcaption>
            <strong>Figure 4.6 </strong> A Quicksort recursion tree
          </figcaption>
        </figure>
        <p>
          As shown in Figure 4.6, a recursion tree for QUICKSORT in which
          PARTITION always produces a 9-to-1 split, yielding a running time of
          O(nlgn). Nodes show subproblem sizes, with per-level costs on the
          right. The per-level costs include the constant c implicit in the Θ(n)
          term.
        </p>
        <h4>Intuition for the average case</h4>
        <p>
          To develop a clear notion of the randomized behavior of quicksort, we
          must make an assumption about how frequently we expect to encounter
          the various inputs. The behavior of quicksort depends on the relative
          ordering of the values in the array elements given as the input, and
          not by the particular values in the array. we will assume for now that
          all permutations of the input numbers are equally likely.
        </p>
        <p>
          When we run quicksort on a random input array, the partitioning is
          highly unlikely to happen in the same way at every level, as our
          informal analysis has assumed. We expect that some of the splits will
          be reasonably well balanced and that some will be fairly unbalanced.
        </p>
        <p>
          In the average case, PARTITION produces a mix of “good” and “bad”
          splits. In a recursion tree for an average-case execution of
          PARTITION, the good and bad splits are distributed randomly throughout
          the tree. Suppose, for the sake of intuition, that the good and bad
          splits alternate levels in the tree, and that the good splits are
          best-case splits and the bad splits are worst-case splits. Figure
          4.7(a) shows the splits at two consecutive levels in the recursion
          tree. At the root of the tree, the cost is n for partitioning, and the
          subarrays produced have sizes n - 1 and 0: the worst case. At the next
          level, the subarray of size n - 1 undergoes best-case partitioning
          into subarrays of size (n - 1) / 2 - 1 and (n - 1) / 2. Let’s assume
          that the boundary-condition cost is 1 for the subarray of size 0.
        </p>
        <p>
          The combination of the bad split followed by the good split produces
          three subarrays of sizes 0, (n- 1) / (2 - 1), and (n - 1) / 2 at a
          combined partitioning cost of Θ(n) + Θ(n - 1) = Θ(n). Certainly, this
          situation is no worse than that in Figure 4.7(b), namely a single
          level of partitioning that produces two subarrays of size (n - 1) / 2,
          at a cost of Θ(n). Yet this latter situation is balanced! Intuitively,
          the Θ(n - 1) cost of the bad split can be absorbed into the Θ(n) cost
          of the good split, and the resulting split is good. Thus, the running
          time of quicksort, when levels alternate between good and bad splits,
          is like the running time for good splits alone: still O(nlgn), but
          with a slightly larger constant hidden by the O-notation.
        </p>
        <figure>
          <img
            src={figure4_7}
            alt=""
          />
          <figcaption>
            <strong>Figure 4.7 </strong> (a) Two levels of a recursion tree for
            quicksort. (b) A single level of a recursion tree that is very well
            balanced.
          </figcaption>
        </figure>
        <p>
          In Figure 4.7, (a) Two levels of a recursion tree for quicksort. The
          partitioning at the root costs n and produces a “bad” split: two
          subarrays of sizes 0 and n - 1. The partitioning of the subarray of
          size n - 1 costs n - 1 and produces a “good” split: subarrays of size
          (n - 1) / 2 - 1 and (n - 1) / 2. (b) A single level of a recursion
          tree that is very well balanced. In both parts, the partitioning cost
          for the subproblems shown with elliptical shading is Θ(n). Yet the
          subproblems remaining to be solved in (a), shown with square shading,
          are no larger than the corresponding subproblems remaining to be
          solved in (b).
        </p>
      </NoteSectionEnd>
    </section>
  )
}


