import React from 'react'
import notebook from '../scss/notebook.module.scss';
import ParchmentTopRight from '../images/Parchment_TopRight.png';
import ParchmentTop from '../images/Parchment_Top.png';
export default function NoteSectionStart({children}) {
  return (
    <section className={notebook.noteSection}>
        <img src={ParchmentTopRight} alt="" className={notebook.parchmentCorner}/>
        <img src={ParchmentTop} alt="" className={notebook.parchmentTop}/>
        <div className={notebook.noteContent}>{children}</div>
    </section>
  )
}
