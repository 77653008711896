import React from 'react'
import {Link} from 'react-router-dom';
import notebook from '../scss/notebook.module.scss';



export default function Newspaper({newspaper, newspaperHeading, volumeNo, link1, link2, link3, link4}) {
  return (
    <section id={notebook.newspaper}>
        <div className={notebook.newspaper} style={{background: `url(${newspaper}) top/100% auto`}} > 
          <img className={notebook.newspaperHeading} src={newspaperHeading} alt="" />
        </div>
        <div className={notebook.chapter}>
          <Link to={link1} className={notebook.chapterLink}>VOL.{volumeNo} NO.1</Link>
          <Link to={link2} className={notebook.chapterLink}>VOL.{volumeNo} NO.2</Link>
          <Link to={link3} className={notebook.chapterLink}>VOL.{volumeNo} NO.3</Link>
          <Link to={link4} className={notebook.chapterLink}>VOL.{volumeNo} NO.4</Link>
        </div>
    </section>
  )
}
