import React from 'react';
import { Helmet } from 'react-helmet';
//components

import Newpaper from '../../common/newspaper';
import NoteSectionStart from '../../common/noteSectionStart';
import NoteSection from '../../common/noteSection';
import NoteSectionEnd from '../../common/noteSectionEnd';
//images
import figure1_1 from '../images/INSERTION-SORT(A).png';
import figure2_1 from '../images/RAM-model.png';
import figure2_2 from '../images/LINEAR-SEARCH (A, n, x).png';
import figure3_1 from '../images/Stacks.png';
import figure3_2 from '../images/Stacks-Operations.png';
import figure3_3 from '../images/Queues.png';
import figure3_4 from '../images/Queues-Operations.png';
import figure3_5 from '../images/Linked-Lists.png';
import figure3_6 from '../images/LIST-SEARCH(L, k).png';
import figure3_7 from '../images/LIST-INSERT(L, x).png';
import figure3_8 from '../images/LIST-DELETE(L, x).png';
import figure4_1 from '../images/free-tree.png';
import figure4_2 from '../images/ordered-tree.png';
import figure4_3 from '../images/binary-tree.png';
import figure4_4 from '../images/complete-binary-tree.png';

//Edit
import newspaper from '../images/newspaper-no1.png'
import newspaperHeading from '../images/no1.png'
const issueNo = 1
const volumeNo = 1
const module = 'algorithms-&-data-structures'

export default function Vol1no1(props) {
  return (
    <section onClick={props.onDropdownClose}>
      <Helmet><title>{`CS: Vol.${volumeNo} No.${issueNo}`}</title></Helmet>

      <Newpaper newspaper={newspaper} newspaperHeading={newspaperHeading} volumeNo={volumeNo} link1={`/notebook/computer-science/${module}/vol${volumeNo}no1`} link2={`/notebook/computer-science/${module}/vol${volumeNo}no2`} link3={`/notebook/computer-science/${module}/vol${volumeNo}no3`} link4={`/notebook/computer-science/${module}/vol${volumeNo}no4`} />
      <NoteSectionStart>
          <h1>Learning Outcomes</h1>
          <ul>
            <li>apply pseudo-code conventions to express algorithms</li>
            <li>analyse the time complexity using Random Access Machine model</li>
            <li>express algorithm time complexity using asymptotic notations</li>
            <li>express linear data structure operations and demonstrate how they work</li>
            <li>discuss the performance, limitation and applications of linear datastructures</li>
            <li>understand the principle of stacks and queues</li>
            <li>operate stacks and queues</li>
            <li>analyse the performance of their operations</li>
          </ul>
          <h2>Foundamental</h2>
          <h3>Algorithms</h3>
          <p>An <strong>algorithm</strong> is a <strong>computational procedure</strong> that generates <strong>output</strong> from <strong>input</strong>, also a tool for solving <strong>computational problems</strong>.</p>

          <h3>Data structures</h3>
          <p>A data structure is a way to store and organize data in order to facilitate access and modifications.</p>

          <h4>Pseudocode conventions</h4>
          <p>Pseudocode can be used to represent a computational procedure that conforms to human reading habits but cannot be understood by machines. As shown in Figure 1-1,</p>
          <ul>
            <li>Indentation indicates block structure. For example, the body of the <strong>for</strong> loop that begins on line 1 consists of lines 2–8, and the body of the <strong>while</strong> loop that begins on line 5 contains lines 6–7 but not line 8. In an <strong>if-else</strong> statement, we indent ’else‘ at the samelevel as its matching ’if‘.</li>
            <li>The loop counter retains its value after exiting the loop. Thus, immediately after a <strong>for</strong> loop, the loop counter’s value is the value that first exceeded the <strong>for</strong> loop bound. The <strong>for</strong> loop header in line 1 is <strong>for</strong> j = 2 to A.length, and so when this loop terminates, j = A.length + 1 (or, equivalently, j = n + 1, since n = A.length). We use the keyword <strong>to</strong> when a <strong>for</strong> loop increments its loop counter in each iteration, and we use the keyword <strong>downto</strong> when a <strong>for</strong> loop decrements its loop counter. When the loop counter changes by an amount greater than 1, the amount of change follows the optional keyword <strong>by</strong>.</li>
            <li>The symbol “ // ” indicates that the remainder of the line is a comment.</li>
            <li>A multiple assignment of the form i = j = e assigns to both variables i and j the value of expression e; it should be treated as equivalent to the assignment j = e followed by the assignment i = j.</li>
            <li>Variables (such as i, j, and key) are local to the given procedure.We shall not use global variables without explicit indication.</li>
            <li>Access array elements by specifying the array name followed by the index in square brackets. For example, A[i] indicates the i<sub>th</sub> element of the array A. The notation “ . . ” is used to indicate arange of values within an array. Thus, A[1 . . j] indicates the subarray of A consisting of the j elements A[1], A[2], . . . , A[j].</li>
            <li>Allow multiple values to be returned in a single <strong>return</strong> statement.</li>
            <li>The boolean operators “and” and “or” are <strong>short circuiting</strong>.</li>
          </ul>
          <figure><img src={figure1_1} alt=""/><figcaption><strong>Figure 1.1</strong> Insertion-Sort(A)</figcaption></figure>
      </NoteSectionStart>

      <NoteSection>
      <h2>Analyzing Algorithms</h2>
        <p>Analyzing an algorithm has come to mean predicting the resources that the algorithm requires. Occasionally, resources such as memory, communication bandwidth, or computer hardware are of primary concern, but most often it is computational time that we want to measure. Generally, by analyzing several candidate algorithms for a problem, we can identify a most efficient one. Such analysis may indicate more than one viable candidate, but we can often discard several inferior algorithms in the process.</p>
        <h3>Random-Access Machine (RAM) model</h3>
        <p>In the RAM model, instructions are executed one after another, with no concurrent operations. The RAM model contains instructions commonly found in real computers: arithmetic (such as add, subtract, multiply, divide, remainder, floor, ceiling), data movement (load, store, copy), and control (conditional and unconditional branch, subroutine call and return). Each such instruction takes a constant amount of time. The data types in the RAM model are integer and floating point (for storing real numbers). In the RAM model, we do not attempt to model the memory hierarchy that is common in contemporary computers. That is, we do not model caches or virtual memory.</p>
        <p>As shown in Figure 2.1, </p>
        <ul>
          <li>
            A Central Processing Unit (CPU) with a
            potentially unbounded bank of memory cells, each of which can hold
            an arbitrary number or character. Memory cells are numbered and
            accessing any cell in memory takes unit time.
          </li>
          <li>
            Instructions are executed one after another. No concurrent
            operations are assumed.
          </li>
          <li>Each instruction takes a constant amount of time.</li>
          <li>
            Instructions are primitive operations such as evaluating an
            expression, assigning a value to a variable, indexing into an array,
            calling a method and returning from a method. They are identifiable
            in pseudocode, and largely independent from the programming
            language.
          </li>
          <li>
            Assuming each line of pseudo-code (with one or a few primitive
            operations) requires a constant time c<sub>i</sub>
          </li>
        </ul>

        <figure><img src={figure2_1}alt=""/><figcaption><strong>Figure 2.1</strong> RAM model</figcaption></figure>

        <h4>Use the RAM model to analyse the &#39; LINEARSEARCH &#39; algorithms</h4>

        <figure><img src={figure2_2} alt="" /><figcaption><strong>Figure 2.2</strong> Linear-Search(A,n,x)</figcaption></figure>

        <p>Running Time: T(n) = (c<sub>2</sub> + c<sub>3</sub>) * n + c<sub>1</sub> + c<sub>2</sub> + c<sub>3</sub> + c<sub>4</sub> + c<sub>5</sub> + c<sub>6</sub> = a * n + b</p>
        <p>The worse case running time: Θ(n)</p>
        <p>The best case running time: Θ(1)</p>
        <p>Time complexity: O(n) or Ω(1)</p>

        <h3>Worst Case and Best Case Scenario</h3>
        <p><strong>Big-theta: </strong> Θ(n<sup>2</sup>) , means it could <strong>only</strong> be some constant times n<sub>2</sub> but not anything else.</p>
        <p>
          <strong>Big-omega:</strong> Ω(n<sup>2</sup>) , an
          <strong>asymptotic lower bound</strong> which means it could be some
          constant times n<sup>2</sup>, n<sup>3</sup> or n<sup>4</sup> , but not
          some constant times n.
        </p>
        <p>
          <strong>Big-oh:</strong> O(n<sup>2</sup>) , an
          <strong>asymptotic upper bound</strong> which means it could be some
          constant times n<sup>2</sup>, n or some constant, but not some
          constant times n<sup>3</sup>
        </p>
        <h4>
          The seven important functions with growth rate in ascending order:
        </h4>
        <table>
          <tr>
            <th>Big-Oh</th>
            <th>Informal Name</th>
          </tr>
          <tr>
            <td>O(1)</td>
            <td>constant</td>
          </tr>
          <tr>
            <td>O(lgn)</td>
            <td>logarithmic</td>
          </tr>
          <tr>
            <td>O(n)</td>
            <td>linear</td>
          </tr>
          <tr>
            <td>O(n<sup>2</sup>)</td>
            <td>quadratic</td>
          </tr>
          <tr>
            <td>O(n<sup>3</sup>)</td>
            <td>cubic</td>
          </tr>
          <tr>
            <td>O(2<sup>n</sup>)</td>
            <td>exponential</td>
          </tr>
        </table>
      </NoteSection>

      <NoteSection>
      <h2>Linear data structures</h2>
          <p>Stacks and queues are dynamic sets in which the element removed from the set by the DELETE operation is prespecified. In a <strong>stack</strong>, the element deleted from the set is the one most recently inserted: the stack implements a <strong>last-in, first-out,</strong> or <strong>LIFO</strong>, policy. Similarly, in a <strong>queue</strong>, the element deleted is always the one that has been in the set for the longest time: the queue implements a <strong>first-in, first-out,</strong> or <strong>FIFO</strong>, policy. There are several efficient ways to implement stacks and queues on a computer. In this section we show how to use a simple array to implement each.</p>
          <h3>Stacks</h3>
          <p>The INSERT operation on a stack is often called PUSH, and the DELETE opera- tion, which does not take an element argument, is often called POP. These names are allusions to physical stacks, such as the spring-loaded stacks of plates used in cafeterias. The order in which plates are popped from the stack is the reverse of the order in which they were pushed onto the stack, since only the top plate is accessible.</p>

          <figure><img src={figure3_1} alt=""/><figcaption><strong>Figure 3.1</strong> An array implementation of a stack S</figcaption></figure>

          <p>In Figure 3.1, Stack elements appear only in the lightly shaded positions. (a) Stack S has 4 elements. The top element is 9. (b) Stack S after the calls PUSH(S, 17) and PUSH(S, 3) (c) Stack S after the call POP(S) has returned the element 3, which is the one most recently pushed. Although element 3 still appears in the array, it is no longer in the stack; the top is element 17.</p>

            <p>As Figure 3.1 shows, we can implement a stack of at most n elements with an array S[1...n]. The array has an attribute S.top that indexes the most recently inserted element. The stack consists of elements S[1...S.top], where S[1] is the element at the bottom of the stack and S[S.top] is the element at the top.</p>
    
            <p>When S.top = 0, the stack contains no elements and is <strong>empty</strong>. We can test to see whether the stack is empty by query operation STACK-EMPTY. If we attempt to pop an empty stack, we say the stack <strong>underflows</strong>, which is normally an error. If S.top exceeds n, the stack <strong>overflows</strong>. (In our pseudocode implementation, we don’t worry about stack overflow.)</p>
            <p>Figure 3.1 also shows the effects of the modifying operations PUSH and POP. Each of the three stack operations takes O(1) time.</p>
            <p>We can implement each of the stack operations with just a few lines of code, as shown in Figure 3.2.</p>
            
            <figure><img src={figure3_2} alt=""/><figcaption><strong>Figure 3.2</strong> Stacks Operations in Pseudocode</figcaption></figure>  

            <h3>Queues</h3>
            <p>We call the INSERT operation on a queue ENQUEUE, and we call the DELETE operation DEQUEUE; like the stack operation POP, DEQUEUE takes no element argument. The FIFO property of a queue causes it to operate like a line of customers waiting to pay a cashier. The queue has a <strong>head</strong> and a <strong>tail</strong>. When an element is enqueued, it takes its place at the tail of the queue, just as a newly arriving customer takes a place at the end of the line. The element dequeued is always the one at the head of the queue, like the customer at the head of the line who has waited the longest.</p>
            <figure>
                <img
                  src={figure3_3}
                  alt=""
                />
                <figcaption><strong>Figure 3.3</strong> A queue implemented using an array Q[1...12]</figcaption>
            </figure>
            <p>In Figure 3.3, Queue elements appear only in the lightly shaded positions. (a) The queue has 5 elements, in locations Q[7...11]. (b) The configuration of the queue after the calls ENQUEUE(Q, 17), ENQUEUE(Q, 3), and ENQUEUE(Q, 5). (c) The configuration of the queue after the call DEQUEUE(Q) returns the key value 15 formerly at the head of the queue. The new head has key 6.</p>
            <p>Figure 3.3 shows one way to implement a queue of at most n - 1 elements using an array Q[1...n]. The queue has an attribute Q.head that indexes, or points to, its head. The attribute Q.tail indexes the next location at which a newly arriving element will be inserted into the queue. The elements in the queue reside in locations Q.head, Q.head + 1, ... ,  Q.tail - 1, where we “wrap around” in the sense that location 1 immediately follows location n in a circular order. When Q.head = Q.tail, the queue is empty. Initially, we have Q.head = Q.tail = 1. If we attempt to dequeue an element from an empty queue, the queue underflows. When Q.head = Q.tail + 1, the queue is full, and if we attempt to enqueue an element, then the queue overflows.</p>
            <p>Figure 3.3 also shows the effects of the ENQUEUE and DEQUEUE operations. Each operation takes O(1) time.</p>
            <p>In our procedures ENQUEUE and DEQUEUE, we have omitted the error checking for underflow and overflow. The pseudocode assumes that n = Q.length, as shown in Figure 3.4.</p>
            <figure>
                <img
                  src={figure3_4}
                  alt=""
                />
                <figcaption><strong>Figure 3.4</strong> Queue Operations in Pseudocode</figcaption>
            </figure>
            <h3>Linked Lists</h3>
            <p>A linked list is a data structure in which the objects are arranged in a linear order. Unlike an array, however, in which the linear order is determined by the array indices, the order in a linked list is determined by a pointer in each object. Linked lists provide a simple, flexible representation for dynamic sets.</p>
            <figure>
                <img
                  src={figure3_5}
                  alt=""
                />
                <figcaption><strong>Figure 3.5</strong> Linked Lists</figcaption>
            </figure>
            <p>As shown in Figure 3.5, each element of a <strong>doubly linked list</strong> L is an object with an attribute key and two other pointer attributes: next and prev. (a) A doubly linked list L representing the dynamic set '{'1, 4, 9, 16'}''. Each element in the list is an object with attributes for the key and pointers (shown by arrows) to the next and previous objects. The next attribute of the tail and the prev attribute of the head are NIL, indicated by a diagonal slash. The attribute L.head points to the head. (b) Following the execution of LIST-INSERT(L, x), where x.key = 25, the linked list has a new object with key 25 as the new head. This new object points to the old head with key 9. (c) The result of the subsequent call LIST-DELETE(L, x), where x points to the object with key 4.</p>
            <p>The object may also contain other satellite data. Given an element x in the list, x.next points to its successor in the linked list, and x.prev points to its predecessor. If x.prev = NIL, the element x has no predecessor and is therefore the first element, or <strong>head</strong>, of the list. If x.next = NIL, the element x has no successor and is therefore the last element, or <strong>tail</strong>, of the list. An attribute L:head points to the first element of the list. If L.head = NIL, the list is empty.</p>
            <p>A list may have one of several forms. It may be either singly linked or doubly linked, it may be sorted or not, and it may be circular or not. If a list is singly linked, we omit the prev pointer in each element. If a list is sorted, the linear order of the list corresponds to the linear order of keys stored in elements of the list; the minimum element is then the head of the list, and the maximum element is the tail. If the list is unsorted, the elements can appear in any order. In a circular list, the prev pointer of the head of the list points to the tail, and the next pointer of the tail of the list points to the head. We can think of a circular list as a ring of elements. In the remainder of this section, we assume that the lists with which we are working are unsorted and doubly linked.</p>
            <h4>Searching a linked list</h4>
            <p>As shown in Figure 3.6, The procedure LIST-SEARCH(L, k) finds the first element with key k in list L by a simple linear search, returning a pointer to this element. If no object with key k appears in the list, then the procedure returns NIL. For the linked list in Figure 3.5(a), the call LIST-SEARCH(L, 4) returns a pointer to the third element, and the call LIST-SEARCH(L, 7) returns NIL.</p>
            <figure>
                <img
                  src={figure3_6}
                  alt=""
                />
                <figcaption><strong>Figure 3.6</strong> List-Search(L, k)</figcaption>
            </figure>
            <p>To search a list of n objects, the LIST-SEARCH procedure takes Θ(n) time in the worst case, since it may have to search the entire list.</p>
            <h4>Inserting into a linked list</h4>
            <p>Given an element x whose key attribute has already been set, the LIST-INSERT procedure in Figure 3.7 “splices” x onto the front of the linked list, as shown in Figure 3.5(b).</p>
            <figure>
                <img
                  src={figure3_7}
                  alt=""
                />
                <figcaption><strong>Figure 3.7</strong> List-Insert(L, x)</figcaption>
            </figure>
            <p>The running time for LIST-INSERT on a list of n elements is O(1).</p>
            <h4>Deleting from a linked list</h4>
            <p>The procedure LIST-DELETE in Figure 3.8 removes an element x from a linked list L. It must be given a pointer to x, and it then “splices” x out of the list by updating pointers. If we wish to delete an element with a given key, we must first call LIST-SEARCH to retrieve a pointer to the element.</p>
            <figure>
                <img
                  src={figure3_8}
                  alt=""
                />
                <figcaption><strong>Figure 3.8</strong> List-Delete(L, x)</figcaption>
            </figure>
            <p>Figure 3.5(c) shows how an element is deleted from a linked list. LIST-DELETE runs in O(1) time, but if we wish to delete an element with a given key, Θ(n) time is required in the worst case because we must first call LIST-SEARCH to find the element.</p>
      </NoteSection>

      <NoteSectionEnd>
        <h2>Non-linear Data Structures</h2>
        <h3>Free trees</h3>
        <p>A free tree is a connected, acyclic, undirected graph. We often omit the adjective “free” when we say that a graph is a tree. If an undirected graph is acyclic but possibly disconnected, it is a forest. Many algorithms that work for trees also work for forests. Figure 4.1(a) shows a free tree, and Figure 4.1(b) shows a forest. The forest in Figure 4.1(b) is not a tree because it is not connected. The graph in Figure 4.1(c) is connected but neither a tree nor a forest, because it contains a cycle.</p>
        <figure>
          <img
            src={figure4_1}
            alt=""
          />
          <figcaption><strong>Figure 4.1</strong> (a) A free tree. (b) A forest. (c) A graph that contains a cycle and is therefore neither a tree nor a forest.</figcaption>
       </figure>
       <h3>Rooted and ordered trees</h3>
       <p>A rooted tree is a free tree in which one of the vertices is distinguished from the others. We call the distinguished vertex the root of the tree. We often refer to a vertex of a rooted tree as a node of the tree. Figure 4.2(a) shows a rooted tree on a set of 12 nodes with root 7.</p>
       <figure>
        <img
          src={figure4_2}
          alt=""
        />
        <figcaption><strong>Figure 4.2</strong> Rooted and ordered trees</figcaption>
       </figure>
       <p>In Figure 4.2, (a) A rooted tree with height 4. The tree is drawn in a standard way: the root (node 7) is at the top, its children (nodes with depth 1) are beneath it, their children (nodes with depth 2) are beneath them, and so forth. If the tree is ordered, the relative left-to-right order of the children of a node matters; otherwise it doesn’t. (b) Another rooted tree. As a rooted tree, it is identical to the tree in (a), but as an ordered tree it is different, since the children of node 3 appear in a different order.</p>
       <p>The number of children of a node x in a rooted tree T equals the degree of x. The length of the simple path from the root r to a node x is the depth of x in T . A level of a tree consists of all nodes at the same depth. The height of a node in a tree is the number of edges on the longest simple downward path from the node to a leaf, and the height of a tree is the height of its root. The height of a tree is also equal to the largest depth of any node in the tree.</p>
       <p>An ordered tree is a rooted tree in which the children of each node are ordered. That is, if a node has k children, then there is a first child, a second child, . . . , and a kth child. The two trees in Figure 4.2 are different when considered to be ordered trees, but the same when considered to be just rooted trees.</p>

       <h3>Binary and positional trees</h3>
       <p>We define binary trees recursively. A binary tree T is a structure defined on a finite set of nodes that either</p>
       <ul>
        <li>contains no nodes, or</li>
        <li>is composed of three disjoint sets of nodes: a <strong>root</strong> node, a binary tree called its left subtree, and a binary tree called its <strong>right subtree</strong>.</li>
      </ul>
      <p>The binary tree that contains no nodes is called the <strong>empty tree</strong> or <strong>null tree</strong>, sometimes denoted NIL. If the left subtree is nonempty, its root is called the <strong>left child</strong> of the root of the entire tree. Likewise, the root of a nonnull right subtree is the <strong>right child</strong> of the root of the entire tree. If a subtree is the null tree NIL, we say that the child is <strong>absent</strong> or <strong>missing</strong>. Figure 4.3 shows a binary tree.</p>
      <figure>
        <img
          src={figure4_3}
          alt=""
        />
        <figcaption><strong>Figure 4.3</strong> Binary trees</figcaption>
       </figure>
       <p>In Figure 4.3, (a) A binary tree drawn in a standard way. The left child of a node is drawn beneath the node and to the left. The right child is drawn beneath and to the right. (b) A binary tree different from the one in (a). In (a), the left child of node 7 is 5 and the right child is absent. In (b), the left child of node 7 is absent and the right child is 5. As ordered trees, these trees are the same, but as binary trees, they are distinct. (c) The binary tree in (a) represented by the internal nodes of a full binary tree: an ordered tree in which each internal node has degree 2. The leaves in the tree are shown as squares.</p>
       <p>A binary tree is not simply an ordered tree in which each node has degree at most 2. For example, in a binary tree, if a node has just one child, the position of the child—whether it is the <strong>left child</strong> or the <strong>right child</strong>—matters. In an ordered tree, there is no distinguishing a sole child as being either left or right. Figure 4.3(b) shows a binary tree that differs from the tree in Figure 4.3(a) because of the position of one node. Considered as ordered trees, however, the two trees are identical.</p>
       <p>We can represent the positioning information in a binary tree by the internal nodes of an ordered tree, as shown in Figure 4.3(c). The idea is to replace each missing child in the binary tree with a node having no children. These leaf nodes are drawn as squares in the figure. The tree that results is a <strong>full binary tree</strong>: each node is either a leaf or has degree exactly 2. There are no degree-1 nodes. Consequently, the order of the children of a node preserves the position information.</p>
       <p>We can extend the positioning information that distinguishes binary trees from ordered trees to trees with more than 2 children per node. In a <strong>positional tree</strong>, the children of a node are labeled with distinct positive integers. The ith child of a node is absent if no child is labeled with integer i. A <strong>k-ary</strong> tree is a positional tree in which for every node, all children with labels greater than k are missing. Thus, a binary tree is a k-ary tree with k D 2.</p>
       <p>A <strong>complete k-ary tree</strong> is a k-ary tree in which all leaves have the same depth and all internal nodes have degree k. Figure 4.4 shows a complete binary tree of height 3. The root has k children at depth 1, each of which has k children at depth 2, etc. Thus, the number of leaves at depth h is kh. Consequently, the height of a complete k-ary tree with n leaves is logk n. The number of internal nodes of a complete k-ary tree of height h is </p>
       <p>1 + k + k<sup>2</sup> + k<sup>h-1</sup> = (k<sup>h</sup> - 1 ) / (k - 1)</p> 
       <p>Thus, a complete binary tree has 2<sup>h</sup> - 1 internal nodes.</p>
       <figure><img src={figure4_4} alt=""/><figcaption><strong>Figure 4.4</strong> A complete binary tree of height 3 with 8 leaves and 7 internal nodes</figcaption></figure>
      </NoteSectionEnd>
    </section>
  )
}


